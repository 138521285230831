import React, {useState} from 'react';
import "./menu.scss";
import Modal from 'react-modal';
import Logo from "./logo";
import { OutboundLink } from 'gatsby-plugin-gtag'
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton
} from 'react-share';
import { useStaticQuery, graphql } from "gatsby"

const customStyles = {
  content : {
    padding: 0,
    border: 0,
    boxShadow: "0 2px 40px 0 rgba(0,0,0,0.15)"
  }
};

function Menu(props) {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                typeform
                url
              }
            }
          }
        `
      )
    const [isOpen, setOpen] = useState(false);
    const openMenu = () => setOpen(true);
    const closeMenu = () => setOpen(false);
    const url = site.siteMetadata.url;
    return (
    <>
        <ul className="header-menu">
            <li className="header-menu-item">
                <span className="header-menu-link" style={{cursor: "inherit"}}>
                SHARE
                </span>
            </li>
            <li className="header-menu-item">
                <FacebookShareButton url={url} className="header-menu-link link-icon link-icon-facebook">
                <span className="visuallyhidden">Facebook</span>
                </FacebookShareButton>
            </li>
            <li className="header-menu-item">
                <LinkedinShareButton url={url} className="header-menu-link link-icon link-icon-linkedin">
                <span className="visuallyhidden">Linkedin</span>
                </LinkedinShareButton>
            </li>
            <li className="header-menu-item">
                <TwitterShareButton url={url} className="header-menu-link link-icon link-icon-twitter">
                <span className="visuallyhidden">Twitter</span>
                </TwitterShareButton>
            </li>
            <li className="header-menu-item">
                <OutboundLink href={site.siteMetadata.typeform} className="header-menu-link header-menu-link-button">
                SIGN UP
                </OutboundLink>
            </li>
        </ul>
        <button className="hamburger-menu"  onClick={openMenu}><span className="visuallyhidden">Open Menu</span></button>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          className="mobileOnly-modal"
          onRequestClose={closeMenu}
        >
            <div className="mobile-menu-wrapper">
                <button className="close-menu" onClick={closeMenu}><span className="visuallyhidden">Close Menu</span></button>
                <div className="menu-logo-wrapper">
                    <Logo />
                    <ul className="header-menu mobile">
                        <li className="header-menu-item">
                          <span className="header-menu-link" style={{cursor: "inherit"}}>
                            SHARE
                          </span>
                        </li>
                        <li className="header-menu-item">
                            <FacebookShareButton url={url} className="header-menu-link link-icon link-icon-facebook">
                            <span className="visuallyhidden">Facebook</span>
                            </FacebookShareButton>
                        </li>
                        <li className="header-menu-item">
                            <LinkedinShareButton url={url} className="header-menu-link link-icon link-icon-linkedin">
                            <span className="visuallyhidden">Linkedin</span>
                            </LinkedinShareButton>
                        </li>
                        <li className="header-menu-item">
                            <TwitterShareButton url={url} className="header-menu-link link-icon link-icon-twitter">
                            <span className="visuallyhidden">Twitter</span>
                            </TwitterShareButton>
                        </li>
                        <li className="header-menu-item">
                            <OutboundLink href={site.siteMetadata.typeform}  className="header-menu-link header-menu-link-button">
                            SIGN UP
                            </OutboundLink>
                        </li>
                    </ul>

                </div>
                
            </div>
        </Modal>
    </>
  );
}
export default Menu;