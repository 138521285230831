import React from 'react';
import "./container.scss";

export function Container(props) {
  return (
    <div className="container">
      {props.children}
    </div>
  );
}
export function PageContainer(props) {
  return (
    <div className="page-container">
      {props.children}
    </div>
  );
}
export default Container;