import React from 'react';
import Container from "./container";
import "./header.scss";
import Logo from "./logo";
import Menu from "./menu";
function Header(props) {
  return (
    <div className="header">

      <Container>
        <div className="header-content">
          <Logo />
          <Menu />
        </div>

      </Container>
    </div>
  );
}
export default Header;